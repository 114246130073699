import Glide from '@glidejs/glide'

window.addEventListener('load', () => {

    //sticky header nav
    var headerTop = document.querySelector(".header__top");
    var nav = document.querySelector(".header__nav");
    var stickyNav = nav.offsetTop ? nav.offsetTop : 128;

    const handleStickyHeader = () => {
        console.log(stickyNav);
        if (window.pageYOffset >= stickyNav) {
            headerTop.classList.add('hidden');
        } else {
            headerTop.classList.remove('hidden');
        }
    }

    handleStickyHeader();
    window.onscroll = handleStickyHeader;
    window.onresize = handleStickyHeader;

    const initHeader = () => {
        const header = document.querySelector('.header');
        if (header) {
            if(window.screen.width < 1200) {
                const toggler = header.querySelector('.header__toggler');
                const navLinks = Array.from(header.querySelectorAll('.nav a'));
                toggler.addEventListener('click', () => {
                    header.classList.toggle('header--open');
                });
                navLinks.forEach((link) => {
                    link.addEventListener('click', () => {
                        header.classList.toggle('header--open');
                    })
                });
            }
        }
    };

    const initPassportSlider = () => {
        if(window.screen.width < 1200) {
            const passportSlider = document.querySelector('.passport__slider');
            if(passportSlider) {
                const slider = new Glide('.passport__slider', {
                    startAt: 0,
                    perView: 1,
                    gap: 0,
                    peek: {
                        before: 14,
                        after: 20,
                    }
                }).mount();
            }
        }
    };

    const initBlogSlider = () => {
        if(window.screen.width < 1200) {
            const blogSuggestionSlider = document.querySelector('.blog-suggestion__slider');
            if(blogSuggestionSlider) {
                const slider = new Glide('.blog-suggestion__slider', {
                    startAt: 0,
                    perView: 1,
                    gap: 0,
                    peek: {
                        before: 14,
                        after: 20,
                    }
                }).mount();
            }
        }
    };

    const initTeamSlider = () => {
        if(window.screen.width < 1200) {
            const teamSlider = document.querySelector('.team__slider');
            if(teamSlider) {
                const slider = new Glide('.team__slider', {
                    startAt: 0,
                    perView: 1,
                    gap: 0,
                    peek: {
                        before: 14,
                        after: 20,
                    }
                }).mount();
            }
        }
    };

    const initAccordions = () => {
        const accordions = Array.from(document.querySelectorAll('.accordion'));
        if (accordions) {
            const resetOtherAccordions = (exclude) => {
                const otherAccordions = accordions.filter((item) => {
                    return item !== exclude;
                });
                otherAccordions.forEach((accordion) => {
                    accordion.classList.remove('accordion--open');
                })
            }
            accordions.forEach((accordion) => {
                accordion.addEventListener('click', () => {
                    resetOtherAccordions(accordion);
                    accordion.classList.toggle('accordion--open');
                })
            });
        }
    };

    const initCheckboxes = () => {
        const checkboxes = Array.from(document.querySelectorAll('.checkbox'));
        if(checkboxes) {
            checkboxes.forEach((checkbox) => {
                checkbox.addEventListener('click', (e) => {
                    if(e.target.nodeName !== 'INPUT') {
                        return;
                    }
                    checkbox.classList.toggle('checkbox--checked');
                });
            })
        }
    };

    const initServicesSlider = () => {
        if(window.screen.width < 1200) {
            const passportSlider = document.querySelector('.service-slider');
            if(passportSlider) {
                const slider = new Glide('.service-slider', {
                    startAt: 0,
                    perView: 1,
                    gap: 0,
                }).mount();
            }
        }
    };

    const initAjaxPagination = () => {
        const blog = document.querySelector('.blog');
        if(blog) {
            const loadMoreBtn = blog.querySelector('.blog__more');
            if(loadMoreBtn) {
                const paginationWrapper = blog.querySelector('.blog__pagination');
                const postsWrapper = blog.querySelector('.blog__grid');
                const template = document.getElementById('js-blog-item-template');
                const categoryId = blog.dataset.category;
                const perPage = blog.dataset.perPage;
                const search = blog.dataset.search;
                const url = blog.dataset.url;
                const maxPages = blog.dataset.maxPages;
                let currentPage = blog.dataset.page;

                const generatePostsHtml = (posts) => {
                    const resultTemplate = document.createElement('template');
                    posts.forEach((post) => {
                        const blogItemTemplate = template.content.cloneNode(true);
                        const blogItem = blogItemTemplate.querySelector('.blog-item');
                        blogItem.href = post.link;
                        blogItem.querySelector('.blog-item__img').src = post.img;
                        blogItem.querySelector('.blog-item__title').innerHTML = post.title;
                        blogItem.querySelector('.blog-item__date').innerHTML = post.date;
                        const viewsWrapper = document.createElement('span');
                        viewsWrapper.innerHTML = post.views;
                        blogItem.querySelector('.blog-item__views').appendChild(viewsWrapper);
                        post.categories.forEach((category) => {
                            const categoryWrapper = document.createElement('span');
                            categoryWrapper.classList.add('blog-item__category');
                            categoryWrapper.innerHTML = category;
                            blogItem.querySelector('.blog-item__row').appendChild(categoryWrapper);
                        })
                        resultTemplate.content.appendChild(blogItem);
                    });
                    return resultTemplate.content;
                }

                const removePagination = () => {
                    paginationWrapper.parentNode.removeChild(paginationWrapper);
                }

                const handleMoreClick = async () => {
                    loadMoreBtn.disabled = true;

                    const response = await fetch(url,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;',
                        },
                        body: (new URLSearchParams(
                            {
                                'action': 'get_paginated_posts',
                                'posts_per_page': perPage,
                                'offset' : perPage * currentPage,
                                'category' : categoryId,
                                'paged' : currentPage,
                                'search' : search,
                            }
                        )).toString(),
                    });
                    const json = await response.json();
                    if(json.status === 'success') {
                        const postsHtml = generatePostsHtml(json.data);
                        postsWrapper.appendChild(postsHtml);
                        loadMoreBtn.disabled = false;
                        currentPage++;
                        if(currentPage >= maxPages) {
                            removePagination();
                        }
                    }
                };

                loadMoreBtn.addEventListener('click', handleMoreClick);
            }
        }
    }

    initHeader();
    initPassportSlider();
    initTeamSlider();
    initBlogSlider();
    initAccordions();
    initCheckboxes();
    initServicesSlider();
    initAjaxPagination();
});